body[data-theme="dark"] {
  background: #1b2531;
}
body[data-theme="light"] .ant-menu-item-selected {
  background: #fff !important;
}
body[data-theme="dark"] .ant-menu-item-selected {
  background: #ffffff20 !important;
}
body[data-theme="light"] .ant-menu-item-active {
  background: #fff !important;
  color: #3e79f7 !important;
}

body[data-theme="light"] .ant-menu-submenu-selected .ant-menu-submenu-title,
body[data-theme="light"] .ant-menu-submenu-selected .ant-menu-submenu-title,
body[data-theme="light"] .ant-menu-dark {
  color: rgba(255, 255, 255, 0.8) !important;
}
body[data-theme="dark"] .bg-theme-dark {
  background-color: #1b2531;
}
body[data-theme="dark"] input:-webkit-autofill,
body[data-theme="dark"] input:-webkit-autofill:hover,
body[data-theme="dark"] input:-webkit-autofill:focus,
body[data-theme="dark"] input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #283142 inset !important;
  -webkit-text-fill-color: white !important;
}

body[data-theme="dark"] .swal2-popup {
  background-color: #283142;
}

body[data-theme="dark"] .swal2-html-container,
body[data-theme="dark"] .swal2-title {
  color: white;
}
body[data-theme="dark"] .ant-modal-confirm .ant-modal-content {
  background-color: #283142;
}
body[data-theme="dark"] .ant-modal-confirm .ant-modal-confirm-title,
body[data-theme="dark"] .ant-modal-confirm .ant-modal-confirm-content,
body[data-theme="dark"] .ant-modal-confirm .ant-btn-default {
  background: none;
  color: white;
}

.ant-form-item-label label {
  width: 100%;
}

.bottom-sheet .ant-drawer-header {
  padding-top: 32px;
}
.bottom-sheet .ant-drawer-content {
  border-radius: 24px 24px 0px 0px;
}

.bottom-sheet .ant-drawer-body {
  padding-bottom: 64px;
}

.ant-form-item-explain-error {
  margin-bottom: 0.5rem !important;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.onboarding h1 span {
  background: rgb(62, 121, 247);
  background: linear-gradient(rgba(62, 121, 247, 1) 25%, rgb(60, 30, 135) 100%);
  color: transparent;

  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Hide the background behind the text */
}

.onboarding h2 {
  opacity: 0.8;
}

.title-case {
  text-transform: capitalize;
}

.ant-select-clear .anticon-close-circle svg {
  fill: red;
  width: 14px;
  height: 14px;
}

.ant-layout-sider-children {
  padding-bottom: 32px;
}

.bg-row-green-4 {
  background-color: #e3fcd8;
}
.bg-row-green-4 .ant-table-cell-fix-left,
.bg-row-green-4 .ant-table-cell-fix-left-last,
.bg-row-green-4 .ant-table-cell-fix-right,
.bg-row-green-4 .ant-table-cell-fix-right-last {
  background-color: #e3fcd8 !important;
}

.bg-row-green-4:hover .ant-table-cell {
  background-color: #e3fcd8 !important;
}
body[data-theme="dark"] .bg-row-green-4 {
  background-color: #154500;
}
body[data-theme="dark"] .bg-row-green-4 .ant-table-cell-fix-left,
body[data-theme="dark"] .bg-row-green-4 .ant-table-cell-fix-left-last,
body[data-theme="dark"] .bg-row-green-4 .ant-table-cell-fix-right,
body[data-theme="dark"] .bg-row-green-4 .ant-table-cell-fix-right-last {
  background-color: #154500 !important;
}

body[data-theme="dark"] .bg-row-green-4:hover .ant-table-cell {
  background-color: #154500 !important;
}

.bg-row-red-4 {
  background-color: #ffbbbb;
}
.bg-row-red-4 .ant-table-cell-fix-left,
.bg-row-red-4 .ant-table-cell-fix-left-last,
.bg-row-red-4 .ant-table-cell-fix-right,
.bg-row-red-4 .ant-table-cell-fix-right-last {
  background-color: #ffbbbb !important;
}

.bg-row-red-4:hover .ant-table-cell {
  background-color: #ffbbbb !important;
}
body[data-theme="dark"] .bg-row-red-4 {
  background-color: #3e0000;
}
body[data-theme="dark"] .bg-row-red-4 .ant-table-cell-fix-left,
body[data-theme="dark"] .bg-row-red-4 .ant-table-cell-fix-left-last,
body[data-theme="dark"] .bg-row-red-4 .ant-table-cell-fix-right,
body[data-theme="dark"] .bg-row-red-4 .ant-table-cell-fix-right-last {
  background-color: #3e0000 !important;
}

body[data-theme="dark"] .bg-row-red-4:hover .ant-table-cell {
  background-color: #3e0000 !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.show-mobile-only {
  display: none;
}
.show-desktop-only {
  display: inline-block;
}

@media (max-width: 576px) {
  .show-mobile-only {
    display: inline-block;
  }
  .show-desktop-only {
    display: none;
  }
}

.tooltip-sidebar {
  left: 120px !important;
}

.logo-default {
  width: 64x;
  height: 64px;
  border-radius: 100%;
  border: 2px solid white;
  margin-top: 24px;
}

.logo-collapsed {
  border-radius: 100%;
  border: 2px solid white;
}

.page-header-alt {
  transition: all 0.2s ease !important;
  -webkit-transition: all 0.2s ease !important;
}

.ant-layout {
  transition: all 0.2s ease !important;
  -webkit-transition: all 0.2s ease !important;
}

.ql-editor * {
  color: #000 !important;
}

body[data-theme="dark"] .ql-snow.ql-toolbar button {
  color: #fff !important;
}
body[data-theme="dark"] .ql-picker-label .ql-stroke {
  stroke: #fff !important;
}

body[data-theme="dark"]
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background-color: #000 !important;
}
body[data-theme="dark"] .ql-snow .ql-picker {
  color: #fff !important;
}

body[data-theme="dark"] button .ql-stroke,
.ql-picker-label,
.ql-stroke {
  fill: none;
  stroke: #fff !important;
}
body[data-theme="light"] button .ql-stroke,
.ql-picker-label,
.ql-stroke {
  fill: none;
  stroke: #000 !important;
}

body[data-theme="dark"] .ql-active .ql-stroke {
  fill: none;
  stroke: #fff !important;
}

body[data-theme="dark"] button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: #fff !important;
  stroke: none;
}

body[data-theme="dark"] .ql-active .ql-fill {
  fill: #fff !important;
  stroke: none;
}
body[data-theme="dark"] .ql-editor * {
  color: #fff !important;
}

.ant-table {
  font-size: 0.8125rem !important;
  border-radius: 0;
}

th.ant-table-cell {
  background: #283593 !important;
  color: #fff !important;
}

.ant-menu-item-group {
  border-top: 0.25px solid #fff;
  margin-bottom: 24px;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0px !important;
}

.ant-table-wrapper table {
  border-radius: 0 !important;
}

.ant-table-wrapper .ant-table .ant-table-title,
.ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0px !important;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0px;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: #ffffff8b;
}

.wysiwyg-output p,
.wysiwyg-output span,
.wysiwyg-output div {
  color: #000 !important;
}

.wysiwyg-output p {
  margin-bottom: 0px;
}

body[data-theme="dark"] .wysiwyg-output,
body[data-theme="dark"] .wysiwyg-output span,
body[data-theme="dark"] .wysiwyg-output div {
  color: #fff !important;
}

body[data-theme="dark"] .ant-alert-info {
  background-color: #001230 !important;
  border: none !important;
}

.border-color-success {
  border-color: #04d182 !important;
}

/* Add this CSS to your stylesheet or style component */
.ant-select-item-option-content {
  user-select: text;
}
